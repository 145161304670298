<template>
  <el-dialog
    :title="type === 1 ? '新增客户信息' : '修改客户信息'"
    :visible.sync="visible"
    width="20%"
    @close="close"
  >
    <el-form :model="customerForm" ref="customerForm" :rules="rules">
      <el-form-item prop="companyName" label="公司名称" label-width="80px">
        <el-input v-model="customerForm.companyName"></el-input>
      </el-form-item>
      <el-form-item prop="contacts" label="联系人" label-width="80px">
        <el-input v-model="customerForm.contacts"></el-input>
      </el-form-item>
      <el-form-item prop="shortName" label="公司简称" label-width="80px">
        <el-input v-model="customerForm.shortName"></el-input>
      </el-form-item>
      <el-form-item prop="address" label="公司地址" label-width="80px">
        <el-input v-model="customerForm.address"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="type != 3">
      <!-- <el-button @click="dialogVisible = false" type="info" size="mini"
            >返回</el-button
            > -->
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('customerForm')"
        >{{
          type === 1 ? $t("addDevice.add") : $t("addDevice.edit")
        }}</el-button
      >
      <!-- <el-button type="primary" size="mini" v-else>修改</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import { customerAdd, customerEdit } from "@/api/customer.js";
import { deepClone } from "@/utils/deepClone";
import i18n from "@/lang";
export default {
  data() {
    return {
      visible: false,
      type: 1, //1新增 2编辑
      customerForm: {},
      rules: {
        companyName: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        contacts: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        shortName: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        address: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.customerForm);
          let obj = JSON.parse(JSON.stringify(this.customerForm));
          console.log(obj);
          if (this.type === 1) {
            this.addInfo(obj);
          } else if (this.type === 2) {
            this.editInfo(obj);
          }
        }
      });
    },
    addInfo(obj) {
      console.log("add", obj);
      customerAdd(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    editInfo(obj) {
      customerEdit(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    open(type, row) {
      console.log(typeof type, type);
      this.visible = true;
      this.type = type;
      this.customerForm = {};
      if (type === 1) {
        this.viewDisable = false;
      } else if (this.type === 2) {
        this.customerForm = deepClone(row);
      } else if (type === 3) {
        this.customerForm = deepClone(row);
      }
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 90% !important
}
/deep/ .el-select{
  width 60% !important
}
/deep/ .el-select .el-input{
  width 100% !important
}
</style>
